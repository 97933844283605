.icons img {

    /* display: block; */
    height: 24px;
    width: 24px;

}

.iconBig {

    /* border: 1px solid red; */
    background-color: transparent;
    border: none;
    display: inline-block;
    cursor: pointer;
    margin: 5px;
    padding: 5px;
    height: 60px;
    width: 60px;
    cursor: pointer;
    outline: none;
    vertical-align: top;

}

.iconBiga i {

    font-size: 50px;

}

.iconBig img {

    /* border: 1px solid red; */
    display: inline-block;
    height: 50px;
    width: 50px;

}

.toolDrawMenu {

  border-radius: 10px;
  border: 1px solid #d3d3d3;
  background-color: #fefefe;

}

.canvasLine {

    height: 10px;
    width: -moz-calc(100% - 10px);
    width: -webkit-calc(100% - 10px);
    width: -o-calc(100% - 10px);
    width: calc(100% - 10px);

}

.styleLine {

    background: transparent;
    display: inline-block;
    font-famiy: Arial;
    font-size: 15px;
    font-weight: bold;
    height: 25px;
    width: 100%;
    margin: 5px 0px;
    text-align: center;
    outline: none;

}

.stylePreset {

    background: transparent;
    border: none;
    border-radius: 10px;
    display: inline-block;
    font-famiy: Arial;
    font-size: 15px;
    font-weight: bold;
    height: 25px;
    margin: 5px;
    text-align: center;
    outline: none;

}

.colorRainbow {

    background: linear-gradient(to right, red, orange, yellow, green, blue, indigo, violet);
    background-clip: text;
    -webkit-background-clip: text;
    color: transparent;

}

/* REFORMATTED ABOVE */

.testing {
  position: fixed;
  left: 0;
  top: 0;
  background-color: white;
}

.testingLayer {
  flex: 0 1 auto;
  border-right: 1px solid black;

}

#planscontainer {

  flex: 0 1 auto;

  position: relative;
  /* left: 0;
  top: 0; */
  overflow: auto;
  height: 100%;
  width: 100%;

}

.canvas {

  position: absolute;
  top: 0;
  left: 0;

}

#canvascontainer {

  position: relative;
  width: 100%;
  height: 100%;
  overflow: auto;

}

/* #planscontainer {

  background-color: white;
  position: fixed;
  left: 0;
  top: 0;
  overflow: auto;
  height: 100vh;
  width: 100vw;

}

.canvas {

  position: absolute;
  top: 0;
  left: 0;

}

#canvascontainer {

  width: 100%;
  height: 100%;
  overflow: auto;

} */

#planscoordsmsg {

    /* display: block; */
    position: absolute;
    right: 0;
    bottom: 0;
    z-index: 2;
    font-size: 15px;

}

#plansZoom {

    /* display: block; */
    position: absolute;
    left: 0;
    bottom: 0;
    z-index: 2;
    font-size: 15px;

}

#plansSlider {

    /* display: block; */
    position: absolute;
    left: 50%;
    bottom: 0;
    z-index: 2;
    font-size: 15px;
    /* bring your own prefixes */
    transform: translate(-50%);

}

/* .planscontainer {

    display: none;
    position: absolute;

} */

.plansDirection {

   text-align: center;
   display: block;
   position: fixed;
   bottom: 50px;
   left: 10px;
   z-index: 2;

}

.plansInfo {

    position: fixed;
    bottom: 10px;
    left: 50px;
    z-index: 2;

}

#plans_refresh {

    position: fixed;
    bottom: 10px;
    left: 10px;
    z-index: 2;

}

.plansdraw {

    display: inline-block;
    /* border: none; */

}

.plans_legend {

    /* background: transparent; */

    border: 1px solid #d3d3d3;
    border-radius: 10px;
    background-color: dodgerblue; /*#fefefe;*/
    color: white;
    display: inline-block;
    font-famiy: Arial;
    font-size: 15px;
    font-weight: bold;
    margin: 5px;
    padding: 5px;
    cursor: pointer;
    text-align: center;
    /* transform: translate(-50%, -50%); */

}

.plans_legend_container {

    position: fixed;
    bottom: 10px;
    text-align: center;
    z-index: 2;

}

.plansdraw_style_header {

    display: flex;
    justify-content: space-between;

}

.plansdraw_style_header-text {

  margin: 5px;
  color: #d3d3d3;
  font-size: 20px;

}

#planslayercontainer {

    /* border: 1px solid red; */
    display: block;
    position: fixed;
    left: 10px;
    top: 48px;
    z-index: 3; /* 3 lets it sit above directions */

}

#planslayerexpand, #planslayerreduce {

    display: block;
    /* float: right; */
    position: absolute;
    top: 0px;
    right: 0px;
    z-index: 1;

}

#planslayertable {

    position: relative;
    display: none;
    width: 150px;
    height: auto;
    max-height: 450px;
    /* overflow: auto; */
    border-radius: 10px;
    border: 1px solid #d3d3d3;
    background-color: #fefefe;
    margin: 5px auto;

}

#planslayertablecontainer {

    position: relative;
    /* display: none; */
    height: auto;
    max-height: 450px;
    overflow: auto;
    /* border-radius: 10px;
    border: 1px solid #d3d3d3;     */
    background-color: #fefefe;
    /* margin: 5px auto; */

}

.plansmenu {

    position: relative;
    display: inline-block;

}

.planstype_index, .planstype_plan {

    display: none;

}

/* slider */

.min-max-slider {position: relative; width: 200px; text-align: center; margin-bottom: 50px;}
.min-max-slider > label {display: none;}
/* span.value {height: 1.7em; font-weight: bold; display: inline-block;} */
/* span.value.lower::before {display: inline-block;} */
/* span.value.upper::before {content: "- "; display: inline-block; margin-left: 0.4em;} */
/* .min-max-slider > .legend {display: flex; justify-content: space-between;} */
/* .min-max-slider > .legend > * {font-size: small; opacity: 0.25;} */
.min-max-slider > input {cursor: pointer; position: absolute;}

/* webkit specific styling */
.min-max-slider > input {
  -webkit-appearance: none;
  outline: none!important;
  background: transparent;
  background-image: linear-gradient(to bottom, transparent 0%, transparent 30%, dodgerblue 30%, dodgerblue 60%, transparent 60%, transparent 100%);
}
.min-max-slider > input::-webkit-slider-thumb {
  -webkit-appearance: none; /* Override default look */
  appearance: none;
  width: 14px; /* Set a specific slider handle width */
  height: 14px; /* Slider handle height */
  background: dodgerblue; /* Green background */
  cursor: pointer; /* Cursor on hover */
  border: 1px solid black;
  border-radius: 100%;
}
.min-max-slider > input::-webkit-slider-runnable-track {cursor: pointer;}
